/**
 * Respond.js (respond.min.js) - A fast & lightweight polyfill for min/max-width CSS3 Media Queries (for IE 6-8, and more)
 * https://github.com/scottjehl/Respond
 *
 * Picurefill (picturefill.min.js) - Polyfill for <picture> element and image srcset attribute
 * http://scottjehl.github.io/picturefill/
 *
 * classList (classlist.min.js) - Cross-browser element.classList method
 * https://github.com/eligrey/classList.js
 */

(function(w, d) {

	'use strict';

	var head = d.head || d.getElementsByTagName('head')[0];
	var jsPath = '/wp-content/themes/kurikka/dist/scripts/';

	/**
	 * Fix IE Mobile 10.0 viewport and Windows 8 snap mode bugs
	 * http://timkadlec.com/2013/01/windows-phone-8-and-device-width/
	 * http://getbootstrap.com/getting-started/#support-ie10-width
	 */

	if (navigator.userAgent.match(/IEMobile\/10\.0/)) {
		var msViewportStyle = d.createElement('style');

		msViewportStyle.appendChild(
			d.createTextNode('@-ms-viewport{width:auto!important}')
		);
		head.appendChild(msViewportStyle);
	}

	/**
	 * Load polyfills conditionally to reduce impact on browsers that
	 * support features.
	 */

	function loadScript(src) {
		var js = d.createElement('script');
		js.src = src;
		head.appendChild(js);
	}

	if (!w.matchMedia || (!w.matchMedia && !w.msMatchMedia)) {
		loadScript(jsPath + 'respond.min.js');
	}

	if (!w.HTMLPictureElement) {
		loadScript(jsPath + 'picturefill.min.js');
	}

	if (!('classList' in d.createElement('_'))) {
		loadScript(jsPath + 'classlist.min.js');
	}
})(this, document);
